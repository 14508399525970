import * as React from "react"

const SvgButtonBackground = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={258}
    height={57}
    viewBox="0 0 258 57"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 6" d="M0 0h258v57H0z" />
      </clipPath>
    </defs>
    <path data-name="Rectangle 7" fill="#e32223" d="M0 0h258v57H0z" />
    <g data-name="Mask Group 1" clipPath="url(#a)">
      <path
        data-name="Path 7"
        d="M222.184-1.953S129.876 46.568 18.296 56.035s253.593 7.692 253.593 7.692l-7.608-72.781-37.025-2.367Z"
        fill="#d21314"
      />
    </g>
  </svg>
)

export default SvgButtonBackground
