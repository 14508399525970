import React from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled/macro"
import { Seo } from "../components/Seo"
import { Layout } from "../components/Layout"
import {
  useSeoData,
  useBlogPrevNextTextData,
  useBlogPostData,
  PageSpecificSchemaFunction,
} from "../components/DataImports"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import { BlogPostContent } from "../components/BlogPostContent"
import SvgButtonBackground from "../images/SVGs/ButtonBackground"
import { theme } from "../components/Theme"

const BlogPost = ({ data, pageContext }) => {
  const BlogPostOuterContainer = styled.article`
    .buttonContainer {
      width: 90%;
      max-width: 1010px;
      margin: 0 auto 15.4375rem auto;
    }
    .buttonInnerContainer {
      display: inline-grid;
      grid-template-columns: auto auto;
      column-gap: 1rem;
    }
    .prevNext {
      display: grid;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.light};
      font-family: ${theme.fonts.altFont};
      font-weight: 700;
      font-size: 1.0625rem;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:hover {
        color: ${theme.colors.secondary};
      }

      svg {
        grid-area: 1/1;
      }
      p {
        grid-area: 1/1;
        text-align: center;
      }
    }
  `
  const { previousPostUri, nextPostUri } = pageContext
  const prevNextText = useBlogPrevNextTextData()
  const postData = useBlogPostData(data)

  return (
    <BlogPostOuterContainer>
      <Seo data={useSeoData(data)} page="blogPost" />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />

      <Layout data={data}>
        <BlogPostContent data={postData} />

        <div className="buttonContainer">
          <div className="buttonInnerContainer">
            {previousPostUri !== null && (
              <Link to={`/blog` + previousPostUri} className="prevNext">
                <SvgButtonBackground />

                <p>{prevNextText.prevText}</p>
              </Link>
            )}
            {nextPostUri !== null && (
              <Link to={`/blog` + nextPostUri} className="prevNext">
                <SvgButtonBackground />
                <p>{prevNextText.nextText}</p>
              </Link>
            )}
          </div>
        </div>
      </Layout>
    </BlogPostOuterContainer>
  )
}
export const blogPostQuery = graphql`
  query individualBlogPostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        canonical
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: NONE
                quality: 100
              )
            }
          }
        }
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      featuredImageAltText {
        featuredImageAltText
      }
      author {
        node {
          firstName
          lastName
        }
      }
      title
      content
      uri
      date(formatString: "LL")
      modified(formatString: "LL")
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default BlogPost
